import * as React from "react";

import Header from "../components/Navbar/Header/Header";
import Footer from "../components/Footer/Footer";

import "../css/pages/business-development.scss";

// Üzletfejlesztés
const BusinessDevelopmentPage = () => {
  return (
    <>
      <Header />
      <main className="business-development-page">Üzletfejlesztés</main>
      <Footer></Footer>
    </>
  );
};

export default BusinessDevelopmentPage;
